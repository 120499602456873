<template>
  <v-card outlined class="d-flex" style="width: 100%; overflow: hidden">
    <v-text-field
      v-model="search"
      :loading="loading"
      dense
      solo
      flat
      :placeholder="`Buscar por ${
        searchType == 'ref' ? 'Referência' : 'Identificador'
      }`"
      ref="buscaProduto"
      style="min-width: 350px"
      :error="error"
      clearable
      hide-details
      @click:clear="clearSearch"
      @keydown.enter.prevent="searchProdutos"
      v-bind="$attrs"
      v-on="$listeners"
      :disabled="disableAll"
    >
      <template v-slot:append>
        <v-btn-toggle v-model="searchType" class="ml-3" mandatory dense>
          <v-btn class="text-caption" value="ref" height="25px"> REF </v-btn>
          <v-btn class="text-caption" value="id" height="25px"> ID </v-btn>
        </v-btn-toggle>
      </template>
    </v-text-field>
    <v-divider vertical style="padding-right: 1px"></v-divider>
    <v-btn
      depressed
      tile
      class="tocs_gray_1"
      color="tabs"
      height="40px"
      style="margin-left: 0.6px"
      :disabled="produto.id || search || disableAll ? true : false"
      @click="dialogSearchProdutos = !dialogSearchProdutos"
    >
      <v-icon>mdi-magnify</v-icon>
    </v-btn>

    <DialogSearchProdutos
      v-if="dialogSearchProdutos"
      :dialogSearchProdutos.sync="dialogSearchProdutos"
      :lista_preco_id="lista_preco_id"
      :moeda_id="moeda_id"
      :deposito_id="deposito_id"
      :produto_sync.sync="produto"
      :typeComp="typeComp"
      :tipo="tipo"
    />
  </v-card>
</template>

<script>
import { buscaProdutos, getOneProduto } from "@/api/produtos/produtos.js";

export default {
  name: "SearchProdutos",

  components: {
    DialogSearchProdutos: () => import("./DialogSearchProdutos.vue"),
  },

  props: {
    lista_preco_id: {
      type: [Number, String],
    },
    moeda_id: {
      type: [Number, String],
    },
    deposito_id: {
      type: [Number, String],
    },
    produto_sync: {
      type: Object,
    },
    typeComp: {
      type: String,
      default: "venda",
    },
    disableAll: {
      type: Boolean,
      default: false,
    },
    clear_search: {
      type: Boolean,
      default: false,
    },
    tipo: {
      type: String,
      default: "",
    },
  },

  data() {
    return {
      loading: false,
      produto: {
        id: null,
        descricao: null,
      },
      search: null,
      searchType: "ref",
      error: false,
      dialogSearchProdutos: false,
    };
  },

  watch: {
    produto() {
      if (!this.produto.id) return;

      if (!this.search) {
        this.search = this.produto.descricao;
      }

      this.$emit("update:produto_sync", this.produto);
    },

    produto_sync() {
      if (this.produto_sync == null) {
        this.clearSearch();
      }
    },

    dialogSearchProdutos() {
      if (!this.dialogSearchProdutos) {
        this.$nextTick().then(() => {
          this.$refs.buscaProduto.focus();
        });
      }
    },

    clear_search() {
      this.clearSearch();
    },
  },

  methods: {
    clearSearch() {
      this.search = null;
      this.produto = {
        id: null,
        descricao: null,
      };
      this.error = false;
      this.$emit("clear-add-item");
    },

    async searchProdutos() {
      if (this.search && !this.produto.id && !this.loading) {
        this.loading = true;

        let filtro = `?search=${this.search}`;
        filtro += `&search_type=${this.searchType}`;
        filtro += this.deposito_id ? `&deposito_id=${this.deposito_id}` : "";
        filtro += this.tipo ? `&tipo=${this.tipo}` : "";

        const response = await buscaProdutos(filtro);
        if (response.length == 1) {
          this.produto = response[0];
          this.search = this.produto.marca
            ? this.produto.marca + " | " + this.produto.descricao
            : this.produto.descricao;

          this.error = false;
        } else {
          this.error = true;
          this.$toast.warning("Produto não encontrado!");
          this.$emit("clear-add-item");
        }
        this.loading = false;
      }
      if (!this.search && !this.produto.id && !this.loading) {
        this.dialogSearchProdutos = true;
      }
    },
  },

  async mounted() {
    if (this.produto_sync?.id && this.typeComp === "fornecedor_servico") {
      if (this.typeComp == "compras"){
        this.produto_sync.is_compra = true;
      } 
      await getOneProduto(this.produto_sync.id).then((response) => {
        this.produto = response;
      });
    }
  },
};
</script>

<style></style>
